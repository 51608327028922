import './footer-section.scss';

const FooterSection = () => {
    return(
        <>
        <div className="container-fluid footer-section-wrapper">
            <div className="footer-heading">
            "We bring all the information you need to <span>keep you at your best</span>..!!"
            </div>
            <div className="footer-body">
                {/* <div className="row">
                    <div className="col-5">First section</div>
                    <div className="col-4">Second section</div>
                    <div className="col-3">Third section</div>
                </div> */}
            </div>
        </div>
        </>
    )

}
export default FooterSection;